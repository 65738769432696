<template>
  <div>
    overview
    <hl-transfer
      :mockData="mockData"
      title="company_name"
      id="company_id"
      :targetKeys="targetKeys"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
     
    }
  },
  created() {
    let old = [
      { name: 'a', age: 10, height: 100, id: 1 },
      { name: 'a', age: 11, height: 100, id: 2 },
      { name: 'b', age: 10, height: 100, id: 3 },
      { name: 'b', age: 11, height: 100, id: 4 },
    ]
    let news = [
      { name: 'a', age: 10 },
      { name: 'a', age: 11 },
      { name: 'b', age: 10 },
      { name: 'b', age: 11 },
      { name: 'a', age: 13 },
      { name: 'b', age: 13 },
    ]
    let keys = [{ name: ['a', 'b'] }, { age: [10, 12] }]
    let keys2 = [{ name: ['a', 'b'] }, { age: [10, 12, 13] }]
    // let news = [
    //   { name: 'a', age: 10 }, 
    //   { name: 'b', age: 10 },  
    // let keys2 = [{ name: ['a', 'b'] }, { age: [10] }]
    let news2 = []
    let newKeys = Object.keys(news[0])
    console.log(newKeys);
    var contains = (a, b) => Object.entries(b).every(([k, v]) => a[k] === v)
    news.forEach((el, idx) => {
      old.forEach((m, n) => {
        if (contains(m, el)) {

          el = Object.assign(el, m)
        }

      })

    })
    // news = [
    // { name: "a", age: 10, height: 100, id: 1 },
    // { name: "a", age: 11, height: 100, id: 2 },
    // { name: "b", age: 10, height: 100, id: 3 },
    // { name: "b", age: 11, height: 100, id: 4 },
    // { name: "a", age: 13 },
    // { name: "b", age: 13 },
    // ]
    console.log(news, "我要的数据在哪里？？？？？");

  },
  methods: {
    replaceObjectKey(arr, key, replaceKey) {
      let newArr = [];
      arr.forEach((item, index) => {
        for (var i = 0; i < key.length; i++) {
          item[key] = item[replaceKey];
        }
        // 删除原始key名
        delete item[replaceKey];
        newArr.push(item);
      });
      return newArr;
    },
    changeArrToObj() {
      let beforeData = [
        { 尺寸: "m", product_id: 0 },
        { 颜色: "红色", product_id: 0 },
        { 尺寸: " m", product_id: 1 },
        { 颜色: "白色", product_id: 1 },
      ]
      let beforeData2 = [
        {
          product_id: 0,
          origin: [
            { 尺寸: "m", product_id: 0 },
            { 颜色: "红色", product_id: 0 },
          ]
        },
        {
          product_id: 1,
          origin: [
            { 尺寸: " m", product_id: 1 }, { 颜色: "白色", product_id: 1 }, { 颜色: "白色", product_id: 1 },
          ]
        }
      ]
      beforeData2.forEach(m => {
        let s = JSON.stringify(m.origin)
        m.origin = JSON.parse(s.replace(/},{/g, ","))

      })
      console.log(beforeData2, '///beforeData2');


      function fn(beforeData) {
        var afterData = []
        beforeData.forEach(item => {
          let flag = afterData.find(item1 => item1.product_id === item.product_id)
          if (!flag) {
            afterData.push({
              product_id: item.product_id,
              product_attr: [item]
            })
          } else {
            flag.product_attr.push(item)
          }
        })
        return afterData
        console.log(afterData, 'afterDataafterData');
      }

    }

  }
}
</script>
<style lang="less" scoped>
</style>